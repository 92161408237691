const host = process.env.REACT_APP_BASE_URL;
//eslint-disable-next-line
export default {
	ENV: process.env.REACT_APP_ENV,
	USER_PAGES: `${host}users/list-page-for-user/`,
	USER_ROLE_CENTER: `${host}users/list-of-school-and-role-for-a-user/`,
	USER_TYPE: `${host}users/get-user-type/`,
	CLASSES: `${host}support/get-class/v1/`,
	SECTION: `${host}users/get-section-class/`,
	USER_PAGE_ACTION: `${host}users/action-for-a-page/`,
	INITIATE_REVERSAL: `${host}payment/request-for-payment-reversal/`,
	PAYMENT_REVERSAL: `${host}payment/get-payment-reversal-request/`,
	PAYMENT_REVERSAL_UPDATE: `${host}payment/update-payment-reversal-request/`,
	STUDENT_DETAILS: `${host}users/get-student-details/`,
	STUDENT_PARENT_DETAILS: `${host}users/get-student-and-parent-detail/`,
	ADMISSION_FORM: `${host}student/create-student-admission/`,
	TOGGLE_COURSE: `${host}users/activate-deactivate-student-course/`,
	STUDENT_COURSES: `${host}users/list-of-course-for-a-student-mycrm/`,
	STUDENT_RESET_PASSWORD: `${host}users/reset-user-password/`,
	CHANGE_USER_PASSWORD: `${host}users/change-password/`,
	CREATE_STUDENT: `${host}users/create-student/v1`,
	COURSE_DETAIL: `${host}support/get-sectionsubject/`,
	CALC_INSTALLMENT_DETAILS: `${host}support/calculate-installment-details/`,
	CALC_HOSTEL_INSTALLMENT_DETAILS: `${host}support/calculate-hostel-installment-details/`,
	STUDENT_INSTALLMENT_DETAIL: `${host}support/get-student-installments-details/`,
	STUDENT_HOSTEL_INSTALLMENT_DETAIL: `${host}support/get-student-hostel-installment-details/`,
	EDIT_INSTALLMENT_PLAN: `${host}support/edit-student-installment-plan/`,
	EDIT_HOSTEL_INSTALLMENT_PLAN: `${host}support/edit-student-hostel-installment-plan/`,
	FEE_SUMMARY_PAYMENT_HISTORY: `${host}support/get-payment-history/`,
	FEE_SUMMARY_HOSTEL_PAYMENT_HISTORY: `${host}support/get-hostetfee-history/`,
	GET_STUDENT_LIST: `${host}support/search-student-support-wise/`,
	GET_STUDENT_LIST_V1: `${host}support/search-student-support-wise/v1/`,
	GET_STUDENT_LIST_FILTER: `${host}users/get-students-crm/`,
	ADD_COURSE: `${host}users/add-course-by-student/`,
	DELETE_STUDENT: `${host}users/delete-student/`,
	EDIT_STUDENT: `${host}users/update-student-details/`,
	GET_CLASS_SECTION_LIVE_CLASS: `${host}support/get-class-section-liveclass/v1/`,
	STUDENT_BATCH_ATTENDANCE: `${host}support/student-batch-attendance/v1/`,
	STUDENT_BATCH_ATTENDANCEV3: `${host}test/v3/student-batch-attendance/`,
	STUDENT_BATCH_ATTENDANCEV3_PRESENT: `${host}test/v3/student-batch-attendance-present/`,
	
	SUBJECT: `${host}support/subject`,
	CHAPTER: `${host}support/chapter`,
	CITY_LIST: `${host}users/get-city-based-on-user/`,
	ACCESS_LOCK: `${host}support/student/access-lock`,
	// LOGIN: `${host}auth/login`,
	// LOGOUT: `${host}auth/logout`,
	// LOGIN: `${host}auth/login-experian`,
	// MULTIPLE_LOGIN: `${host}auth/multiple-login`,
	HUB: `${host}support/hub`,
	SUBJECT_CHAPTER_LIST: `${host}support/subject-chapter-list`,
	COURSE: `${host}support/course`,
	FINANCE_MANAGE_PAYMENT: `${host}payment/detail`,
	TABLET_MANAGEMENT: `${host}student/tablet-details/`,
	POST_TABLET_DATA: `${host}student/post-tablet-details/`,
	FINANCE_MANAGE_PAYMENT_REVERSED: `${host}payment/reversed`,
	BATCH: `${host}support/batch`,
	BANK_DEPOST: `${host}payment/bank-deposit/`,
	NO_COST_EMI: `${host}payment/no-cost-emi/`,
	RECEIVE_PAYMENT: `${host}support/receive-payment/`,
	RECEIVE_HOSTEL_PAYMENT: `${host}support/receive-hostel-payment/`,
	MYCLASSROOM_CENTRE: `${host}support/list/centres`,
	PENDING_CHEQUE: `${host}payment/pending/cheques`,
	UPDATE_PENDING_CHEQUE: `${host}payment/update/cheque/`,
	PENDING_DEPOSIT: `${host}payment/pending/deposits`,
	UPDATE_PENDING_DEPOSITS: `${host}payment/update/deposit/`,
	CENTRE_MANAGEMENT: `${host}support/centre`,
	PENDING_EMI: `${host}payment/pending/no-cost-emi`,
	UPDATE_PENDING_EMI: `${host}payment/update/no-cost-emi/`,
	FACULTY: `${host}test/faculty/`,
	ADD_FACULTY: `${host}test/faculty/add`,
	UPDATE_FACULTY: `${host}test/faculty/update/`,
	GET_FACULTY_DETAILS: `${host}test/faculty/`,
	MOBILE_NUMBER_DUPLICATE: `${host}test/faculty/check-duplicate/`,
	GET_SUBJECT_LIST: `${host}test/faculty/assign-reviewer`,
	GET_UNASSIGNED_QUESTION_LIST: `${host}test/faculty/unassigned/`,
	GET_ASSIGNED_QUESTION_LIST: `${host}test/faculty/assigned/`,
	GET_CHAPTER_LIST: `${host}test/faculty/assign-reviewer/chapter`,
	GET_ALLOWED_FACULTIES: `${host}test/faculty/allowed-faculties`,
	ASSIGN_QUESTIONS: `${host}test/faculty/assign-questions`,
	CONFIRM_CASH: `${host}payment/cash-confirm`,
	ADD_CASH_DEPOSIT: `${host}payment/cash-deposit/add`,
	CASH_DEPOSIT_LIST: `${host}payment/cash-deposit/view`,
	FINANCE_MANAGER_VIEW: `${host}payment/cash-deposit/fm/view`,
	UPDATE_PENDING_CASH_DEPOSIT: `${host}payment/cash-deposit/update/`,
	PENDING_CASH_CONFIRM: `${host}payment/cash-confirm/pending`,
	FM_CASH_POSITION: `${host}payment/cash-position/fm/view`,
	ACCOUNTANT_DASHBOARD: `${host}payment/cash-position/view`,
	ACCOUNTANT_DASHBOARD_SUMMARY: `${host}payment/cash-position/summary`,
	BULK_BANK_DEPOSIT: `${host}payment/bulk-bank-deposit`,
	STUDENT_INSTALLMENTS: `${host}support/student-installment`,
	CREATE_STUDENT_INSTALLMENTS: `${host}support/create-student-installment/v1`,
	CREATE_LEAD: `${host}users/lead/create`,
	UPDATE_LEAD: `${host}users/lead/update`,
	LEAD: `${host}support/lead`,
	CHECK_MOBILE: `${host}users/mobile/check-duplicate`,
	MPP: `${host}test/mpp`,
	MPP_UPLOAD: `${host}test/mpp/upload`,
	MPP_PREVIEW: `${host}test/mpp/preview`,
	MPP_GET_DETAILS: `${host}test/mpp/confirm`,
	MPP_CREATE: `${host}test/mpp/confirm`,
	MPP_DOWNLOAD_TEMPLATE: `${host}test/mpp/download/template`,
	STUDENT_VERIFY_MOBILE: `${host}users/student/verify-mobile`,
	STUDENT_VERIFY_OTP: `${host}users/student/verify-otp`,
	QB: `${ host }test/qb`,

	HDFC_CALLBACK_URL: `${host}support/hdfc-callback-url`,

	//FOR OMR SHEET
	OMR_EXAM_DATA: `${host}test/v3/fetch-all-questions-omr/`,
	OMR_SUBMIT_RESPONSE: `${host}test/v3/save-exam-questions-omr/`,
	OMR_SUBMIT_EXAM_CALCULATE: `${host}test/v3/submit-exam-omr/`,
	OMR_Exam_Order: `${host}test/v3/offline-omr/`,
	Firebase_Marks_Calculation : `${host}test/v3/get-exam-data-from-firebase/`,
};
